<router>
{
    name: 'Units Of Measure',
}
</router>

<template lang="pug">
v-container
    v-row
        v-col
            Uom(
                @refresh="refresh"
            )
    v-row
        v-col
            Factors(
                @refresh="refresh"
            )

</template>

<script lang="js">
import Uom from '@/components/analytics/uom.vue';
import Factors from '@/components/analytics/factors.vue';

export default {
    meta: {
        role: 'analytics-uom'
    },
    components: {
        Uom,
        Factors
    },
    data () {
        return {};
    },
    methods: {
        refresh () {
            this.$children.forEach(child => {
                child.initialize();
            });
        }
    },
    created () {
        this.$store.dispatch('connectSocket');
    },
};
</script>
